/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/algoliasearch@3.35.1/dist/algoliasearchLite.min.js
 * - /npm/instantsearch.js@4.0.0/dist/instantsearch.production.min.js
 * - /npm/owl.carousel@2.3.4/dist/owl.carousel.min.js
 * - /npm/headroom.js@0.11.0/dist/headroom.min.js
 * - /npm/headroom.js@0.11.0/dist/jQuery.headroom.min.js
 * - /npm/moment@2.29.1/moment.min.js
 * - /npm/moment-timezone@0.5.33/builds/moment-timezone-with-data.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
